<template>
    <v-row v-if="!isMobile">
        <v-col cols="4" justify="center">
            <v-img :src="`images/${imgSrc}`" :alt="title" max-height="80%" max-width="80%" class="image-fit ma-6"></v-img>
        </v-col>
        <v-col justify="left" cols="7" class="mt-4">
            <h2 justify="left">{{title}}</h2>
            <div style="width: 60%;">
                <p v-html="description"></p>
            </div>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-img :src="`images/${imgSrc}`" :alt="title" contain max-height="80%" max-width="80%" class="image-fit my-6"></v-img>
        <h2 justify="left">{{title}}</h2>
        <div>
            <p v-html="description"></p>
        </div>
    </v-row>
</template>

<script>
export default {
  name: 'DescriptiveImage',
  props: ['title', 'description', 'imgSrc'],
  methods: {
    getTextWidth(txt) {
      this.element = document.createElement('canvas');
      this.context = this.element.getContext('2d');
      this.context.font = '700 24px Squada One,cursive,sans-serif';
      const width = this.context.measureText(txt).width;
      return parseInt(width);
    },
  },
};
</script>
