<template>
    <v-container :class="`${isMobile ? 'faq-container.mobile' : 'faq-container'}`">
        <div :style="`width: ${isMobile ? '100%' : '50%'};`">
            <h2>Frequently Asked Questions</h2>
            <v-row v-for="(faq, index) in faqs" :key="index" class="question-row mt-4">
                <v-btn v-on:click="faq.visible = !faq.visible" >
                    <v-icon v-if="faq.visible">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                    {{index+1}}. {{faq.question}}</v-btn>
                <p class="ml-6 pa-4" v-if="faq.visible">{{faq.answer}}</p>
            </v-row>
        </div>
        <div :style="`width: ${isMobile ? '100%' : '50%'};`">
            <v-img src="images/TopRankedSuperHerKid.jpg" max-height="75%" contain class="image-fit ma-6"></v-img>
        </div>
    </v-container>
</template>

<script>
export default {
  name: 'FAQ',
  data: () => ({
    faqs: [
      {
        visible: false,
        question: 'Do you accept insurance?',
        answer: `No, Crescendo Speech Therapy is currently private pay only and does not accept insurance.
        However, we provide you with documentation that you can use to submit to your insurance company for reimbursement.`,
      },
      {
        visible: false,
        question: 'Do I need an evaluation?',
        answer: `Yes, a speech-language evaluation is required for all new patients. If your child is currently in infants and toddlers,
        has an IEP, or has a current treatment plan from a previous therapist within the past year then a formal evaluation is not required. `,
      },
      {
        visible: false,
        question: 'Will you come to my house?',
        answer: `No, I provide services from my home office in Middletown, Maryland`,
      },
      {
        visible: false,
        question: 'How long will sessions last?',
        answer: `A standard speech-therapy session lasts for 30 minutes; however, longer sessions may be appropriate depending on the child.`,
      },
      {
        visible: false,
        question: 'How much does it cost?',
        answer: `Evaluations are $300 and typically take 1.5-2 hours depending on the child.
        A comprehensive evaluation report will be provided within 30 days. A 30-minute therapy session costs $75 and a 60-minute session costs $120.`,
      },
      {
        visible: false,
        question: 'Can you meet with my child\'s other therapists?',
        answer: `Yes, Crescendo Speech Therapy believes that collaboration is key! A child makes the most progress when all team members are on the same page.`,
      },
    ],
  }),
};
</script>
<style lang="scss" >
  .faq-container {
    display: flex;
    flex-direction: row;
  }
  .faq-container.mobile {
    display: flex;
    flex-direction: column;
  }
  .question-row {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
</style>
