<template>
  <div class="d-flex flex-column">
    <div><v-img :src="`images/${imgSrc}`" :alt="title" contain height="350px" width="350px" class="image-fit"></v-img></div>
    <div align="center">
        <!--<h2>{{title}}</h2> -->
    </div>
    <div class="ml-6">
        <p style="max-width: 700px">{{description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageWithSubText',
  props: ['title', 'description', 'imgSrc'],
};
</script>
