import axios from 'axios';
const SERVER_BASE_URL='https://crescendospeechtherapy.com/api/';

export default {
  /**
     * Makes an API call to api/email and returns the results.  this is a test API
     */
  async testGetEmail() {
    const url = SERVER_BASE_URL + 'email';
    const config = {
      method: 'get',
      url: url,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    };
    const response = await axios(config);
    return response.data;
  },

  /**
     * Makes an API call to api/send and sends in the contact info so theserver can handle it and send an email.
     * @param {string} firstName The contacts first name
     * @param {string} lastName The contacts last name
     * @param {string} email The contacts email address
     * @param {string} phoneNumber The contacts phone number (optional)
     * @param {string} subject The subject of the message
     * @param {string} message The content of the message
     */
  async sendContactEmail(firstName, lastName, email, phoneNumber, subject, message) {
    const url = SERVER_BASE_URL + 'email';
    const header = {'Content-Type': 'application/json'};
    const payload = {
      'firstName': firstName,
      'lastName': lastName,
      'email': email,
      'phoneNumber': phoneNumber,
      'subject': subject,
      'message': message,
    };
    console.log(`Requesting API call to post to ` + url);
    const response = await axios.post(url, payload, header);
    console.log(`'Response was ${JSON.stringify(response)}`);
    return response.status == 200;
  },
  async generateThumbnail(filename) {
    const url = SERVER_BASE_URL + 'resource/generateThumbnail/' + filename;
    console.log(`Requesting API call to post to ` + url);
    const response = await axios.get(url);
    return response.status == 200;
  },
};
