import Vue from 'vue';
import App from './App.vue';
// import './assets/styles.css';
import router from './router';
import vuetify from './plugins/vuetify';
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = false;
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-196014831-1',
  router,
});

Vue.mixin({
  computed: {
    isMobile() {
      return typeof window.orientation !== 'undefined';
    },
  },
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
