<template>
    <div>
        <v-app-bar class="mb-16" color="white" height="120" dark fixed >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="primary"></v-app-bar-nav-icon>
            <v-row>
                <v-col>
                    <!-- All of this is to make the v-btn invisible and to disable the click effects/highlighting -->
                    <v-btn :ripple="false" color="white" :to="'/'" id="no-background-hover" :elevation="0">
                        <v-img src="images/CrescendoLogo.png" max-height="120" max-width="120" contain/>
                    </v-btn>
                </v-col>
                <v-col align-self="center">
                    <v-row justify="center">
                        <v-btn icon href="https://www.linkedin.com/in/alyssagilliganslp" target="blank" class="condensed-icon">
                            <v-icon color="primary">mdi-linkedin</v-icon>
                        </v-btn>
                        <v-btn icon href="https://www.facebook.com/Crescendospeechtherapy" target="blank" class="condensed-icon">
                            <v-icon color="primary">mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn v-if="false" icon href="http://www.google.com">
                            <v-icon color="primary">mdi-youtube</v-icon>
                        </v-btn>
                        <v-btn icon href="https://www.instagram.com/crescendoslptherapy/" target="blank" class="condensed-icon">
                            <v-icon color="primary">mdi-instagram</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" enable-resize-watcher app color="white">
                <v-list nav dense align-self="center" justify="center">
                    <v-list-item v-for="nav in navigation" :key="nav" :value="nav.active" color="primary"
                        text
                        :to="nav === 'Home' ? '/' : nav"
                        :exact="nav.exact"
                    >
                        {{nav}}
                    </v-list-item>
                </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
  name: 'MobileNavBar',
  props: ['navigation'],
  data: () => ({
    drawer: false,
  }),
};
</script>

<style lang="scss" >
    .mobile-message {
        color: red;
        font-size: 8pt;
        flex-grow: 1;
        flex-shrink: 1;
        font-weight: bold;
    }
    .icons {
        display: flex;
        flex: 1;
        flex-grow: 2;
    }
    .condensed-icon {
        width: 24px !important;
        height: 24px !important;
        margin-left: 4px;
        margin-right: 4px;
    }
</style>
