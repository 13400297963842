<!-- eslint-disable max-len -->
<template>
  <div>
    <v-container>
      <v-row justify="center" align="center" class="mt-5">
        <p class="black--text text-center border" style="font-size:16px; font-style: italic;">
        We currently do not accept insurance; however, we are happy to provide a superbill for you to submit for reimbursement to your insurance company. Be
        sure to check with your provider to see if you would be eligible for reimbursement!
        </p>
      </v-row>
    </v-container>

    <v-container class="mt-10">
      <DescriptiveImage
        title="Complimentary Phone Consultation:"
        imgSrc="ChildReadingBook.jpg"
        description="You can schedule a complimentary 30-minute phone consultation to discuss your child's strengths and needs.
        This may be helpful in determining if your child may need further evaluation or finding an appropriate referral.
        <br/><br/>
        Please email <a target=&quot;blank&quot; href=&quot;mailto:alyssa.gilligan@crescendospeechtherapy.com&quot;>Alyssa</a> to schedule your free consultation!"
      />
      <DescriptiveImage
        title="Comprehensive Evaluations:"
        imgSrc="AlyssaSittingAtTableSpeechTherapyFrederick.jpg"
        description="An evaluation is required if one has not been completed within the past year.  Evaluations analyze your child's language skills,
        articulation skills, social skills, play skills, and may also include assessments of their voice quality and fluency. Evaluations are
        completed in 1.5-2 hours and include a detailed evaluation report that will be available to you within 30 days."
      />
      <DescriptiveImage
        title="Augmentative and Alternative Communication (AAC) evaluations:"
        imgSrc="AACEval.jpg"
        description="An Augmentative and alternative communication (AAC) evaluation is completed to determine and recommend methods, devices, aids,
        techniques, symbols, and/or strategies to augment spoken language to optimize communication. This evaluation will include assessment of your
        child's receptive and expressive language as well as extensive trialing of multiple AAC systems to determine the best fit for your child"
      />
      <DescriptiveImage
        title="Individualized Therapy"
        imgSrc="ClappingWithCastin.jpg"
        description="Following your child's evaluation, an individualized treatment plan will be developed based on your child's needs.
        Depending on your child's age, therapy may have a parent training focus or it may be more of a direct service model. There are many factors that
        determine the appropriate therapy model for your child all of which will be discussed in the first session!"
      />
      <DescriptiveImage
        title="PROMPT Therapy"
        imgSrc="PromptingChild.jpg"
        description="PROMPT is best known for using tactile-kinesthetic (I.e., touch cues), to help your child form words. However,
        PROMPT is a holistic approach that systematically determines where motor breakdowns occur in your child's motor-speech system.
        <br/><br/>
        PROMPT is appropriate for a wide variety of children including those with autism, apraxia of speech, cerebral-palsy, downs syndrome and more! "
      />
    </v-container>
  </div>
</template>

<script>
import DescriptiveImage from '../components/DescriptiveImage';
export default {
  name: 'Services',
  components: {DescriptiveImage},
  data: () => ({}),
};
</script>
<style lang="scss">
.quote {
  font-size: 24px;
  max-width: 420px;
  display: flex;

  &:before {
    content: '\F0757';
    font-family: 'Material Design Icons';
    font-size: 72px;
    color: #002e5d;
    position: relative;
    top: -50px;
    padding-right: 10px;
  }

  &:after {
    content: '\F027E';
    font-family: 'Material Design Icons';
    font-size: 72px;
    color: #002e5d;
    position: relative;
    top: 50px;
  }
}
</style>
