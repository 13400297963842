<template>
  <v-container justify="left" align="left">
      <h2>{{title}}</h2>
      <v-row>
        <v-col v-for="resource in resources" :key="resource.filename">
          <PdfPreview :filename=resource.filename :title=resource.title />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import PdfPreview from './PdfPreview';
export default {
  name: 'ResourceRow',
  components: {PdfPreview},
  data: () => ({
  }),
  props: {
    resources: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
};
</script>
