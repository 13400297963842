<template>
  <v-row justify="center" class="mt-4">
    <v-col :cols="isMobile ? 0 : 4">
      <v-img src="images/AboutAlyssaSpeechSLP.jpg" alt="Speech-language Pathologist" contain max-height="85%"/>
    </v-col>
    <v-col justify="left" :cols="isMobile ? 0 : 4" class="pt-0">
      <h1>About</h1>
      <h2>Alyssa Gilligan, M.S., CCC-SLP</h2>
      <h3>Speech-language Pathologist</h3>
      <div class="border">
        <p class="pt-3">
            Hi! My name is Alyssa Gilligan. I am the owner and founder of Crescendo Speech Therapy!
            I am also a toddler mom and a big sister to my autistic brother. I pull from my personal experiences as a mother and as a sibling of
            my autistic brother to guide my approach that combines my clinical training and evidence based strategies with compassion and a true
            understanding. These two boys have been two huge influences in my life and professional career!
        </p>
        <p />
        <p>
            I believe that all behavior is communication and all children deserve to be heard.
            My therapy is play-based because following a child's lead in play is the most powerful way to enter their world! By tapping into their interests,
            I create genuine, spontaneous, and meaningful communication.
        </p>
        <p />
        <p>
            I have been a speech-language pathologist for 8 years and have worked with a wide variety of children ranging from babies to high-schoolers.
            I specialize in early intervention specifically for Autistic children. I have specialized training in motor speech disorders as I am PROMPT trained
            to the “bridging” level, am trained in Dynamic Temporal and Tactile Cueing (DTTC), and I have training in Oral Placement Therapy (OPT).
            Additionally, I have extensive training and experience with Augmentative and Alternative Communication (AAC) including LAMP and other PRC devices,
            TouchChat HD, PECS, PODD, and GoTalk.
        </p>
        <p />
        <p>
            I received my Masters of Speech-language Pathology from Towson University and completed my internship and clinical fellowship at the Center
            for Autism and Related Disorders at the Kennedy Krieger Institute. I then worked at a top autism treatment center in Baltimore,
            Maryland for nearly 6 years before starting Crescendo Speech Therapy.
        </p>
    </div>
    </v-col>
    <v-col :cols="isMobile ? 0 : 4">
        <v-img src="images/AlyssaDanWeddingTherapyFrederick.jpg" alt="I am also a mom to a baby boy and a big sister to my autistic brother"
            contain max-height="84%" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'About',
  data: () => ({}),
};
</script>
