<template>
    <v-container class="scrolling-wrapper mx-4">
        <v-col :class="`testimonial ${isMobile ? 'm-space' : 'space'}`" v-for="testimonial in testimonials" :key="testimonial.title">
            <v-row class="section"><v-icon size="100" color="secondary">mdi-format-quote-open</v-icon></v-row>
            <v-row class="section"><h3 class="text-decoration-underline">{{testimonial.title}}</h3></v-row>
            <v-row class="section"><p class="font-italic">{{testimonial.description}}</p></v-row>
        </v-col>
    </v-container>
</template>

<script>
export default {
  name: 'Testimonials',
  props: ['testimonials'],
};
</script>


<style lang="scss" >
    .scrolling-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        .testimonial {
            flex: 0 0 auto;
            flex-wrap: nowrap;
            display: block;
            .section {
                display: block;
                margin: 4px 4px 4px 4px;
                align-self: center;
            }
        }
    }
    .m-space {
        width: 100%
    }
    .space {
        width: 33%;
    }

</style>
