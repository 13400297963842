<template>
  <v-footer color="secondary">
    <v-row justify="space-between" align="center" class="mx-3 mt-1">
      <v-col align="center">
        <v-row class="mb-1"><a href="mailto:alyssa.gilligan@crescendospeechtherapy.com">alyssa.gilligan@crescendospeechtherapy.com</a></v-row>
        <v-row class="mb-1">Crescendo Speech Therapy, LLC</v-row>
        <!--<v-row class="mb-1">22 Knoll Side Lane</v-row>-->
        <v-row class="mb-1">Middletown, MD 21769</v-row>
        <v-row class="mb-1">(240) 356-8794</v-row>
      </v-col>
      <v-col align="center">
        <v-img contain :max-height="`${isMobile ? 50 : 100}`" :max-width="`${isMobile ? 50 : 100}`" :src="'./images/CrescendoLogo.png'"></v-img>
      </v-col>
      <v-col>
        <v-row justify="end">
          <v-btn icon href="https://www.linkedin.com/in/alyssagilliganslp" target="blank">
            <v-icon color="white">mdi-linkedin</v-icon>
          </v-btn>
          <v-btn icon href="https://www.facebook.com/Crescendospeechtherapy" target="blank">
            <v-icon color="white">mdi-facebook</v-icon>
          </v-btn>
          <v-btn v-if="false" icon href="http://www.google.com" target="blank">
            <v-icon color="white">mdi-youtube</v-icon>
          </v-btn>
          <v-btn icon href="https://www.instagram.com/crescendoslptherapy/" target="blank">
            <v-icon color="white">mdi-instagram</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
