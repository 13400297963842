<template>
    <v-app-bar class="mb-16" color="white" height="120" dark fixed >
        <v-row>
            <v-col cols="2">
                <!-- All of this is to make the v-btn invisible and to disable the click effects/highlighting -->
                <v-btn :ripple="false" color="white" :to="'/'" id="no-background-hover" :elevation="0">
                    <v-img src="images/CrescendoLogo.png" max-height="120" max-width="120" contain/>
                </v-btn>
            </v-col>
            <v-col cols="7" align-self="center">
                <v-row justify="center">
                    <v-btn color="primary"
                        v-for="nav in navigation" :key="nav"
                        text
                        :to="nav === 'Home' ? '/' : nav"
                    >
                        {{nav}}
                    </v-btn>
                </v-row>
            </v-col>
            <v-col align-self="center" cols="3">
                <v-row justify="center">
                <v-btn icon href="https://www.linkedin.com/in/alyssagilliganslp" target="blank">
                    <v-icon color="primary">mdi-linkedin</v-icon>
                </v-btn>
                <v-btn icon href="https://www.facebook.com/Crescendospeechtherapy" target="blank">
                    <v-icon color="primary">mdi-facebook</v-icon>
                </v-btn>
                <v-btn v-if="false" icon href="http://www.google.com">
                    <v-icon color="primary">mdi-youtube</v-icon>
                </v-btn>
                <v-btn icon href="https://www.instagram.com/crescendoslptherapy/" target="blank">
                    <v-icon color="primary">mdi-instagram</v-icon>
                </v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar',
  props: ['navigation'],
};
</script>

<style lang="scss" >
    // Last thing required to hide the effects of the button hovering effects
    #no-background-hover::before {
        background-color: transparent !important;
        border-radius: 0;
    }
    .message {
        color: red;
        font-size: 16pt;
    }
</style>
