<template>
  <v-app>
    <v-container class="mb-8" fluid>
      <MobileNavBar v-if="isMobile" :navigation="navigation" />
      <NavBar v-else :navigation="navigation" />
    </v-container>
    <v-main class="mt-16">
      <v-container fluid>
        <router-view ></router-view>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from './components/NavBar';
import MobileNavBar from './components/MobileNavBar';
import Footer from './components/Footer';
export default {
  name: 'App',
  components: {NavBar, Footer, MobileNavBar},
  data: () => ({
    navigation: ['Home', 'About', 'Services', 'Resources', 'FAQ', 'Contact'],
  }),
};
</script>
<style>
@font-face {
  font-family: "CooperHewitt";
  src: local("CooperHewitt"),
   url(./fonts/cooper-hewitt/CooperHewitt-Medium.ttf) format("truetype");
}
</style>
