<template>
    <v-row no-gutters :class="`${isMobile ? 'hero-image-m' : 'hero-image'}`"
    :style="`background-image:url(./images/${isMobile ? 'MissionPageMobile.jpg' : 'MissionPage.jpg'})`" justify="center" >
        <div>
            <h1>Crescendo Speech Therapy</h1>
            <h3 >Growing with every measure</h3>
            <br/>
            <h2 class="primary--text">Providing In-person speech-language therapy in Middletown, Maryland</h2>
            <h2 class="primary--text">And telehealth services for all of Maryland</h2>
            <v-btn rounded :class="`primary--text ${isMobile ? 'mt-1' : 'mt-5'}`" color="#ef3220" to="/Services">Services</v-btn>
        </div>
    </v-row>
</template>

<script>
export default {
  name: 'Hero',
};
</script>

<style lang="scss" >
    .hero-image {
        background-size: cover;
        width:100%;
        height: 950px;
        div {
            width:50%;
            text-align: center;
            margin-top: 8%; // Vertically center is hard, just add a margin to the top
            background-color: rgba(255, 255, 255, 0.5);
            height: 40%;
            h3 {
                font-size: 30px;
                letter-spacing: 10px;
            }
            h1 {
                font-size: 70px;
            }
            h2 {
                font-size: 30px;
            }
            .hero-span {
                background: #b8e3e4;
            }
        }
    }
    .hero-image-m {
        background-size: 100% 100%;
        div {
            width: 80%;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.5);

            h3 {
                font-size: 14px;
                letter-spacing: 6px;
            }

            h1 {
                font-size: 30px;
            }
            h2 {
                font-size: 16px;
            }

            .hero-span {
                background: #b8e3e4;
            }
        }
    }
</style>
