<template>
    <v-container justify="center" align="center" class="mt-5">
        <h1>Resources</h1>
        <ResourceRow :resources="parentResources" title="Parent Resources"/>
        <ResourceRow :resources="languageDevelopment" title="Language Development"/>
    </v-container>
</template>

<script>
import ResourceRow from '../components/ResourceRow';
export default {
  components: {ResourceRow},
  name: 'Resources',
  data: () => ({
    parentResources: [
      {
        filename: '15_Read_Aloud_Tips.pdf',
        title: '15 Tips',
      }, {
        filename: 'Apraxia-Kids-Fast-Facts.pdf',
        title: 'Apraxia Fast Kid Facts',
      },
    ],
    languageDevelopment: [
      {filename: 'ASHA_Year1to2.pdf', title: '1-2 Years'},
      {filename: 'ASHA_Year2to3.pdf', title: '2-3 Years'},
      {filename: 'ASHA_Year3to4.pdf', title: '3-4 Years'},
      {filename: 'ASHA_Year4to5.pdf', title: '4-5 Years'},
    ],
  }),
};
</script>
