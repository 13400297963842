<template>
    <v-row>
        <v-col justify="left" cols="7">
            <h3 justify="left">{{title}}</h3>
            <a :href="`resources/${this.filename}`" target="_blank">
              <v-img :src="imagePath" width="200" @error="fetchImage"/>
            </a>
        </v-col>
    </v-row>
</template>

<script>
import Resource from '../services/apiService';
const pdfjs = require('pdfjs-dist/build/pdf.js');
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry.js');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export default {
  name: 'PdfPreview',
  props: {
    filename: {
      type: String,
    },
    title: {
      type: String,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    imagePath() {
      const imgPath = `images/${this.filename.replace('.pdf', '.thumb.png')}`;
      return imgPath;
    },
  },
  methods: {
    async fetchImage(event) {
      console.log(`Image failed to load, fetch`);
      const didFetch = await Resource.generateThumbnail(this.filename);
      if (didFetch) {
        console.log('Successfully loaded image');
      } else {
        console.log('Unable to load image');
      }
    },
  },
};
</script>
