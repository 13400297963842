import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#002e5d',
        // secondary: '#51b59f',
        secondary: '#5db4e8',
        accent: '#ff8878',
        error: '#b71c1c',
      },
    },
  },
});
