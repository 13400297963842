<template>
    <v-form v-model="isValid">
        <v-container v-if="!isMobile" bg grid-list-md flex-start>
            <v-layout fluid align-center>
                <v-flex><h1>Contact Us</h1></v-flex>
                <v-flex><h3><a href="mailto:alyssa.gilligan@crescendospeechtherapy.com">alyssa.gilligan@crescendospeechtherapy.com</a></h3></v-flex>
                <v-flex><h3>Phone: (240) 356-8794</h3></v-flex>
            </v-layout>
            <v-row>
            <v-col cols="7" justify-space-bewteen>
                <v-layout flex-column>
                    <h2>Name <span class="red--text">*</span></h2>
                    <v-layout flex-row justify-space-around>
                        <v-flex class="mr-6"><v-text-field filled label="First Name" v-model="firstName" :rules="required" /></v-flex>
                        <v-flex class="mr-6"><v-text-field filled label="Last Name" v-model="lastName" :rules="required" /></v-flex>
                    </v-layout>
                    <h2>Contact <span class="red--text">*</span></h2>
                    <v-layout flex-row justify-space-around>
                        <!-- Concat both sets of rules, because well why not -->
                        <v-flex class="mr-6"><v-text-field filled label="Email" v-model="email" :rules="required.concat(emailValidation)" /></v-flex>
                        <!-- Only Optional Field -->
                        <v-flex class="mr-6"><v-text-field filled label="Phone" v-model="phone" /></v-flex>
                    </v-layout>
                    <h2>Subject <span class="red--text">*</span></h2>
                    <v-flex class="mr-6"><v-text-field filled v-model="subject" :rules="required" /></v-flex>
                    <h2>Message <span class="red--text">*</span></h2>
                    <v-flex class="mr-6"><v-textarea filled auto-grow rows="10" row-height="25" v-model="message" :rules="required" /></v-flex>
                    <v-flex align-self-end><v-btn depressed color="primary" :disabled="!isValid" v-on:click="submit">Submit</v-btn></v-flex>
                </v-layout>
                </v-col>
                <v-col cols="5">
                    <v-img src="images/CrescendoLogo.png" alt="Crescendo Speech Therapy" contain class="image-fit ma-6"></v-img>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else>
            <h1>Contact Us</h1>
            <h3><a href="mailto:alyssa.gilligan@crescendospeechtherapy.com">alyssa.gilligan@crescendospeechtherapy.com</a></h3>
            <h3>Phone: (240) 356-87942</h3>
            <h2>First Name <span class="red--text">*</span></h2>
            <v-flex class="mr-6"><v-text-field filled label="First Name" v-model="firstName" :rules="required" /></v-flex>
            <h2>Last Name <span class="red--text">*</span></h2>
            <v-flex class="mr-6"><v-text-field filled label="Last Name" v-model="lastName" :rules="required" /></v-flex>
            <h2>Contact <span class="red--text">*</span></h2>
            <!-- Concat both sets of rules, because well why not -->
            <v-flex class="mr-6"><v-text-field filled label="Email" v-model="email" :rules="required.concat(emailValidation)" /></v-flex>
            <!-- Only Optional Field -->
            <v-flex class="mr-6"><v-text-field filled label="Phone" v-model="phone" /></v-flex>
            <h2>Subject <span class="red--text">*</span></h2>
            <v-flex class="mr-6"><v-text-field filled v-model="subject" :rules="required" /></v-flex>
            <h2>Message <span class="red--text">*</span></h2>
            <v-flex class="mr-6"><v-textarea filled auto-grow rows="10" row-height="25" v-model="message" :rules="required" /></v-flex>
            <v-flex align-self-end><v-btn depressed color="primary" :disabled="!isValid" v-on:click="submit">Submit</v-btn></v-flex>
        </v-container>
    </v-form>
</template>

<script>
import Email from '../services/apiService';

export default {
  name: 'Contact',
  methods: {
    submit: async function(event) {
      console.log(`Sending an email fron Contact to ${this.email}`);
      const didSubmit = await Email.sendContactEmail(this.firstName, this.lastName, this.email, this.phone, this.subject, this.message);
      if (didSubmit) {
        alert(`Thank you ${this.firstName} ${this.lastName}!\nAn email has been sent to ${this.email} to confirm your request.
Please check your spam filter if you do not see a response from @crescendospeechtherapy.com within the next minute.
If you find the email in your spam folder please unblock communication from @crescendospeechtherapy.com`);
      } else {
        alert('An Error occurred while requesting contact.  Please try again.');
      }
    },
  },
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    phone: '',
    isValid: false,
    required: [
      (v) => !!v || 'This is a required field.',
    ],
    emailValidation: [
      function(v) {
        // Too long to fit on one line so break it up into the pre and post @ sign expressions
        const preRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))/;
        const postRegex = /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        const regex = new RegExp(preRegex.source + postRegex.source);
        return regex.test(v) || 'Email must be valid';
      },
    ],
  }),
};
</script>
<style lang="scss" >
    h1 {
        font-size: 52px;
    }
</style>
