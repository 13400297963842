<template>
    <div justify="center" align="center">
        <Hero />
        <v-img src="images/CrescendoLogo.png" alt="Crescendo Speech Therapy" max-height="250" max-width="250" class="image-fit ma-6 mb-0"></v-img>
        <h1 class="mt-8" justify="center">Our Mission</h1>
        <p :class="`${isMobile ? 'mx-2' : 'mx-16'} mb-10 border`">
            Crescendo Speech Therapy aims to provide high quality, child centered, speech-language therapy to children of all abilities.
            Using play-based strategies with a focus on parent and family training, we strive to make therapy fun and engaging while also implementing current
            evidence-based strategies.
        </p>
        <h1>Types of Therapy</h1>
        <div>
            <v-row>
                <v-col>
                <ImageWithSubText
                    title="Language Therapy"
                    imgSrc="Language.png"
                    description="I use a variety of therapeutic strategies to cater to each child's needs with a focus on play therapy resulting in
                    a child and family centered approach to intervention."
                />
                </v-col>
                <v-col>
                <ImageWithSubText
                    title="Articulation Therapy"
                    imgSrc="Articulation.png"
                    description="I use fun and exciting activities to keep your child's interest and combine it with evidenced based strategies
                    resulting in a large number of practice trials for the targeted sound. "
                />
                </v-col>
                <v-col>
                <ImageWithSubText
                    title="Apraxia"
                    imgSrc="Apraxia.png"
                    description="I implement evidenced-based strategies including PROMPT,
                    Dynamic Temporal and Tactile Cueing, and multi-sensory cues to improve your child's motor speech abilities."
                />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ImageWithSubText
                        title="Augmentative and Alternative Communication"
                        imgSrc="AAC.png"
                        description="I use my specialized training in  AAC systems including LAMP and other PRC systems,
                        PECS, PODD, and TouchChat to determine an appropriate communication system for your child."
                    />
                </v-col>
                <v-col>
                <ImageWithSubText
                    title="Parent Training"
                    imgSrc="Parent Training.png"
                    description="Using strategies I learned from my training in Hanen as well as my years of experience,
                    I teach you the same therapeutic strategies I use with your child to make everyday activities moments of learning!"
                />
                </v-col>
                <v-col>
                <ImageWithSubText
                    title="Telehealth"
                    imgSrc="Telehealth.png"
                    description="Using a HIPPA compliant platform,  I offer fun and engaging telehealth services.
                    I keep your child's engagement by making therapy exciting with a green screen or playing games!
                    If your child is under 3, I use a parent coaching model to make you the therapist!"
                />
                </v-col>
            </v-row>
        </div>
        <h1>Testimonials</h1>
        <!-- Unable to change the thickness of a v-divider so made my own -->
        <div style="background-color:#002e5d; height: 5px; width:100%;"></div>

        <Testimonials :testimonials="testimonials" />

    </div>
</template>

<script>
import Hero from '../components/Hero';
import ImageWithSubText from '../components/ImageWithSubText';
import Testimonials from '../components/Testimonials.vue';
export default {
  name: 'Home',
  components: {Hero, ImageWithSubText, Testimonials},
  data: () => ({
    testimonials: [{'title': 'Mom of 4 year old boy',
      'description': 'Alyssa has a fun, engaging personality which instantly puts kids at ease.' +
      'He genuinely enjoys his sessions with her. She has helped him go from no language to consistently speaking in two to three word phrases.'},
    {'title': 'Mom of 7 year old boy', 'description':
      'Alyssa unlocked something in him no one else had seemed able to reach.' +
      'We had his first word in 3 months, followed by proficiency in PECS and LAMP.' +
      'Within 18 months, he was speaking in sentences. We are eternally grateful for her skill, love and perseverance.'},
    {'title': 'Mom of 9 year old boy and 4 year old girl', 'description':
    '[My son] wasn\'t saying many words when he started, but over time I could see how Alyssa made a huge impact!' +
    'He was meeting his goals and his words were becoming clearer!' +
    'I highly recommend Alyssa as she is a wonderful speech teacher that offers a lot of experience and genuinely cares about the kids.' +
     'My two children enjoy working with Alyssa and have grown so much in their speech and language development.'}],
  }),
};
</script>
